import { useAppHeaderStyles } from 'components/Layout/layoutStyles';
import {
  Header,
  Box,
  Button,
  useMantineColorScheme,
  Divider,
  ActionIcon,
  Flex,
  Menu,
  UnstyledButton,
  Group,
} from '@mantine/core';
import { Icon } from '@iconify/react';
import AppLogo from 'components/Logo/AppLogo';
import UserMenu from 'components/User/UserMenu';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { can } from 'helpers/userUtils';
import { useMe } from 'store/userStore';
import { Impersonator } from 'components/Impersonator/Impersonator';
import { Permission, Role } from 'api/users';
import { isArray } from 'lodash';
import React from 'react';
import UserRoleBadge from 'views/Users/components/UserRoleBadge';

type MenuItem = {
  title: string;
  link?: string;
  permission?: Permission | Permission[];
  subMenu?: MenuItem[];
};

const AppHeader = () => {
  const { t } = useTranslation('core');
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const me = useMe();
  const { classes } = useAppHeaderStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === 'dark';

  const handleLogout = async () => {
    await logout({ openUrl: false });
    window.location.replace(window.location.origin + '/login');
  };

  const handleLogin = async () => {
    await loginWithRedirect({
      async openUrl(url) {
        window.location.replace(url);
      },
    });
  };

  const renderMenuItem = (item: MenuItem) => {
    if (item.subMenu) {
      return (
        <Menu position="bottom-start" offset={0} withArrow zIndex={1000}>
          <Menu.Target>
            <UnstyledButton className={classes.navLink}>
              <Group spacing="xs">
                {item.title}
                <Icon icon="mdi:chevron-down" width={16} />
              </Group>
            </UnstyledButton>
          </Menu.Target>
          <Menu.Dropdown>
            {item.subMenu.map((subItem, index) => (
              <Menu.Item
                key={index}
                component={NavLink}
                to={subItem.link || '#'}>
                {subItem.title}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <NavLink to={item.link || '#'} className={classes.navLink}>
        {item.title}
      </NavLink>
    );
  };

  const menuItems: MenuItem[] = React.useMemo(
    () => [
      {
        title: t('core:chat'),
        link: '/chat',
        permission: [Permission.COACHING_READ, Permission.COACHING_WRITE, Permission.THERAPY_ALL],
      },
      {
        title: 'Users',
        link: '/users/search?sortBy=createdAt&order=desc',
        permission: [Permission.MODERATION_READ],
      },
      {
        title: t('core:my-clients'),
        link: '/users/clients?sortBy=createdAt&order=desc',
        permission: [Permission.COACHING_READ, Permission.COACHING_WRITE, Permission.THERAPY_ALL],
      },
      {
        title: t('core:action-items'),
        link: '/coach/action_items',
        permission: [Permission.COACHING_READ, Permission.COACHING_WRITE],
      },
      {
        title: t('core:scheduled-messages-view:scheduled-messages'),
        link: '/coach/scheduled_messages',
        permission: Permission.COACHING_WRITE,
      },
      {
        title: t('core:triggers-view:page-title'),
        link: '/action_item_triggers',
        permission: Permission.MODERATION_WRITE,
      },
      {
        title: t('core:cbt-builder'),
        link: '/cbt',
        permission: [Permission.CONTENT_READ, Permission.CONTENT_WRITE],
      },
      {
        title: t('core:news-view:news'),
        link: '/news',
        permission: [Permission.CONTENT_READ, Permission.CONTENT_WRITE],
      },
      {
        title: t('core:notifications'),
        link: '/notifications',
        permission: [Permission.CONTENT_READ, Permission.CONTENT_WRITE],
      },
      {
        title: t('core:therapy-view:therapy'),
        link: '/therapy',
        permission: [Permission.CONTENT_READ, Permission.CONTENT_WRITE],
      },
      {
        title:
          me?.role === Role.THERAPIST
            ? t('core:therapy-view:my-sessions')
            : t('core:therapy-view:therapy-sessions'),
        link: '/therapy-sessions',
        permission: [
          Permission.MODERATION_READ,
          Permission.MODERATION_WRITE,
          Permission.THERAPY_ALL,
        ],
      },
      {
        title: t('core:roles-view:roles'),
        link: '/roles',
        permission: Permission.ADMINISTRATION_ALL,
      },
      {
        title: t('core:system-view:dev-tools'),
        permission: Permission.DEV_ALL,
        subMenu: [
          {
            title: t('core:system-view:pipelines'),
            link: '/system/pipelines',
            permission: Permission.DEV_ALL,
          },
          {
            title: t('core:system-view:dead-letters-abbr'),
            link: '/system/dead-letters',
            permission: Permission.DEV_ALL,
          },
          {
            title: t('core:system-view:email-builder'),
            link: '/system/email-builder',
            permission: Permission.DEV_ALL,
          },
        ],
      },
    ],
    [me?.role, t],
  );

  return (
    <Header height={68} p="sm" className={classes.header} fixed>
      <AppLogo />
      {me && isAuthenticated && (
        <Flex gap={8} className={classes.nav}>
          {menuItems
            .filter(
              (item) =>
                !item.permission ||
                can(me, ...(!isArray(item.permission) ? [item.permission] : item.permission)),
            )
            .map((item, i) => (
              <React.Fragment key={i}>
                {i > 0 && <Divider orientation="vertical" />}
                {renderMenuItem(item)}
              </React.Fragment>
            ))}
        </Flex>
      )}
      <Flex align="center" wrap="nowrap" gap={8}>
        {!isAuthenticated && (
          <Button
            onClick={handleLogin}
            leftIcon={<Icon icon="ri:login-circle-line" width={16} />}>
            {t('core:login')}
          </Button>
        )}
        {me && (
          <>
            <Impersonator />
            <UserRoleBadge
              role={me.role ?? me.userRole?.role}
              size="sm"
              sx={{ overflow: 'visible' }}
            />
            <Box>
              <UserMenu user={me} onLogout={handleLogout} />
            </Box>
          </>
        )}
        <ActionIcon
          onClick={() => toggleColorScheme()}
          color={dark ? 'yellow' : 'dark'}
          variant="transparent">
          <Icon icon={dark ? 'ph:sun' : 'ph:moon'} width={18} />
        </ActionIcon>
      </Flex>
    </Header>
  );
};

export default AppHeader;
