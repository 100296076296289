import React from 'react';
import AppLayout from 'components/Layout/AppLayout';
import { Route, Routes } from 'react-router-dom';

const HomeView = React.lazy(() => import('views/Home/HomeView'));


const UnAuthenticatedApp = () => {
  return (
    <AppLayout>
      <Routes>
        <Route index element={<HomeView />} />
        <Route path="*" element={<HomeView />} />
      </Routes>
    </AppLayout>
  );
};

export default UnAuthenticatedApp;
