import { showNotification } from '@mantine/notifications';
import { QueryClient, QueryKey } from '@tanstack/react-query';
import { get, isEmpty, isNull, isObject, isUndefined, set, escapeRegExp } from 'lodash';
import { ZodError, ZodSchema } from 'zod';

export const isFalsy = (obj: any, undefinedOnly = true) =>
  undefinedOnly
    ? isUndefined(obj)
    : isUndefined(obj) || isNull(obj) || obj === '' || (isObject(obj) && isEmpty(obj));

export const withoutUndefined = (obj: any, withoutEmpty = false) => {
  if (!obj || Array.isArray(obj) || typeof obj !== 'object') {
    return obj;
  }

  return Object.keys(obj).reduce((cleanedObj, key: string) => {
    if (isFalsy(obj[key], !withoutEmpty)) {
      return cleanedObj;
    } else {
      cleanedObj[key] = obj[key];
      return cleanedObj;
    }
  }, {} as any);
};

export const mutationErrorHandler = (error: any) => {
  const reader = error.response?.body.getReader();
  if (reader) {
    reader.read().then(({ value }: any) => {
      const decoder = new TextDecoder('utf-8');
      const { message } = JSON.parse(decoder.decode(value));
      showNotification({
        title: `${error.response.status}: ${error.response.statusText}`,
        message,
        color: 'red',
      });
    });
  } else {
    showNotification({
      title: error.name ?? 'Unknown error',
      message: error.message,
      color: 'red',
    });
  }
};

export const queryResponseValidator =
  <T>(schema: ZodSchema) =>
  (response: any): T => {
    try {
      return schema.parse(response);
    } catch (err: any) {
      if (err instanceof ZodError) {
        const message = `${err.errors[0].message}, at path '${err.errors[0].path.join('.')}'`;
        throw new Error(message);
      }
      throw err;
    }
  };

const flattenObject = (obj: Record<string, any>, parentKey = ''): string[] => {
  const keyValuePairs: string[] = [];

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      const value = obj[key];

      if (value === null) {
        keyValuePairs.push(`${encodeURIComponent(fullKey)}=null`);
      } else if (value instanceof Date) {
        keyValuePairs.push(
          `${encodeURIComponent(fullKey)}=${encodeURIComponent(value.toISOString())}`,
        );
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        keyValuePairs.push(...flattenObject(value, fullKey));
      } else if (Array.isArray(value)) {
        keyValuePairs.push(...flattenArray(value, fullKey));
      } else if (value !== undefined) {
        keyValuePairs.push(`${encodeURIComponent(fullKey)}=${encodeURIComponent(String(value))}`);
      }
    }
  }

  return keyValuePairs;
};

const flattenArray = (arr: Array<any>, parentKey: string): string[] => {
  return arr.flatMap((item, index): string[] => {
    if (item === null) {
      return [`${encodeURIComponent(parentKey)}[]=null`];
    } else if (typeof item === 'object' && !Array.isArray(item)) {
      return flattenObject(item, `${parentKey}[${index}]`);
    } else if (item !== undefined) {
      return [`${encodeURIComponent(parentKey)}[]=${encodeURIComponent(String(item))}`];
    }
    return [];
  });
};

export const toQueryStr = (obj: Record<string, any>): string => {
  return flattenObject(obj).join('&');
};

const parseValue = (value: any) => {
  if (value === 'null') {
    return null;
  } else if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  } else if (!isNaN(Number(value)) && value !== '') {
    return Number(value);
  } else {
    return value;
  }
};

export const fromQueryStr = <T = any>(queryString: string, schema?: any) => {
  const result = queryString.split('&').reduce((obj, pair) => {
    const [key, value] = pair.split('=').map(decodeURIComponent);

    const parsedValue = parseValue(value);

    if (key.endsWith('[]')) {
      const arrayKey = key.slice(0, -2);
      const existingValue = get(obj as any, arrayKey, []);
      set(obj as any, arrayKey, [...existingValue, parsedValue]);
      return obj;
    }

    set(obj as any, key, parsedValue);
    return obj;
  }, {} as T);

  return schema ? queryResponseValidator<T>(schema)(result) : result;
};

export function getLatestQuery(queryClient: QueryClient, key: QueryKey) {
  const queries = queryClient.getQueryCache().findAll(key);

  if (!queries || queries.length === 0) {
    return null;
  }

  const latestQuery = queries.reduce((latest, current) => {
    // @ts-ignore: updatedAt is not a recognized property on QueryObject but exists in the implementation
    return current.state.updatedAt > latest.state.updatedAt ? current : latest;
  }, queries[0]);

  return latestQuery;
}

export const strToBoolean = (value: string | boolean) => {
  return typeof value === 'string' ? value.toLowerCase() === 'true' : value;
};

export const replacePlaceholders = (
  str: string,
  placeholders: Record<string, string>,
  startDelimiter = '[',
  endDelimiter = ']',
): string => {
  const re = new RegExp(
    `${escapeRegExp(startDelimiter)}(${Object.keys(placeholders).join('|')})${escapeRegExp(
      endDelimiter,
    )}`,
    'g',
  );

  return str.replace(re, ($0, $1) => placeholders[$1] ?? $0);
};

export const getLogzIoUrl = (traceId: string) => {
  return `https://app.logz.io/#/dashboard/osd/discover/?_a=(columns:!(message),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'logzioCustomerIndex*',key:traceId,negate:!f,params:(query:'${traceId}'),type:phrase),query:(match_phrase:(traceId:'${traceId}')))),index:'logzioCustomerIndex*',interval:auto,query:(language:lucene,query:''),sort:!(!('@timestamp',desc)))&_g=(refreshInterval:(display:Off,section:0,value:0),time:(from:now%2FM,to:now%2FM))&accountIds=859776&switchToAccountId=859776`;
};
